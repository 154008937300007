import React, { createContext, useState, useCallback } from "react";
import getData from "helpers/GetContent";

export const BlogContext = createContext();

export const BlogProvider = ({ children }) => {
  const [blogData, setBlogData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const getBlogData = useCallback(async () => {
    try {
      setError(false);
      setIsLoading(true);
      const data = await getData("blogs");
      setBlogData(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchData = useCallback(() => {
    getBlogData();
  }, [getBlogData]);

  return (
    <BlogContext.Provider value={{ fetchData, blogData, isLoading, error }}>
      {children}
    </BlogContext.Provider>
  );
};
