import React, { useEffect, useContext, useState } from "react";
// UI
import FetchHandling from "components/UI/FetchHandling";
import BigTitle from "components/UI/BigTitle";
// context
import { BlogContext } from "context/Blog/BlogContext";
// components
import BlogBox from "./Components/BlogBox";
import Container from "components/Container/Container";
import CatBox from "./Components/CatBox";

import { Helmet, HelmetProvider } from "react-helmet-async";

const Blog = () => {
  const { fetchData, blogData, isLoading, error } = useContext(BlogContext);
  const [selectedCategorie, setSelectedCategorie] = useState();
  const [filteredData, setFiltredData] = useState([]);

  const handleCatogorie = (cat) => {
    setSelectedCategorie(cat);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedCategorie) {
      const filteredData = blogData?.blogs.filter(
        (item) => item.category === selectedCategorie
      );
      setFiltredData(filteredData);
    } else {
      // If no category is selected, set filteredData to the entire blogData
      setFiltredData(blogData?.blogs || []);
    }
  }, [selectedCategorie, blogData?.blogs]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>Trends in Fit out Works - Interior & Exterior Fit outs</title>
        <meta
          name="description"
          content="We follow the trends in fit out works to meet the customers demand with our fit out contracting services in Abu Dhabi. Contact us for best interior and exterior fit out solutions."
        />
      </Helmet>
      <main>
        <BigTitle title={"Our blog"} />
        <Container>
          <FetchHandling isError={error} isLoading={isLoading} />

          {!isLoading && !error && (
            <div className="flex flex-col-reverse gap-6 lg:flex-row lg:gap-10 xll:gap-32">
              <BlogBox data={filteredData} />
              <CatBox
                data={blogData}
                onClick={handleCatogorie}
                selectedCategorie={selectedCategorie}
              />
            </div>
          )}
        </Container>
      </main>
    </HelmetProvider>
  );
};

export default Blog;
