import React, { createContext, useState, useCallback } from "react";
import getData from "helpers/GetContent";

export const ProjectContext = createContext();

export const ProjectProvider = ({ children }) => {
  const [projectData, setProjectData] = useState(null);
  const [slugProject, setSlugProject] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const getProjectData = useCallback(async () => {
    try {
      setError(false);
      setIsLoading(true);
      const data = await getData(`projects`);

      setProjectData(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getProjectDataSLug = useCallback(async (slug) => {
    try {
      setError(false);
      setIsLoading(true);
      const data = await getData(`projects`, slug);

      if (slug) {
        setSlugProject(data);
      } else {
        setProjectData(data);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return (
    <ProjectContext.Provider
      value={{
        getProjectDataSLug,
        getProjectData,
        projectData,
        slugProject,
        isLoading,
        error,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};
