import { useState } from "react";
import ImageSkelton from "components/UI/ImageSkelton";
const Mission = ({ content }) => {
  const [imageIsLoading, setImageIsLoading] = useState(true);
  const handleImageLoading = () => {
    setImageIsLoading(false);
  };
  return (
    <section className="mt-secondary lg:mt-primary">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 lg:gap-6 xl:gap-26 xll:gap-24  ">
        {content.map(({ title, subTitle, image }, index) => (
          <div
            key={index}
            className=" flex flex-col gap-y-2 lg:even:flex-col-reverse font-philo"
          >
            <div>
              <h6 className="text-primary text-2xl">{title}</h6>
              <p
                className="font-philo font-medium text-xl md:text-2xl lg:text-3xl"
                dangerouslySetInnerHTML={{ __html: subTitle }}
              />
            </div>
            <div className="lg:max-h-[500px]">
              {imageIsLoading && <ImageSkelton />}
              <img
                className={` h-full object-cover ${
                  index % 2 === 0 ? "w-full lg:w-3/4" : "w-full"
                }  ${imageIsLoading ? "hidden" : "block"}`}
                onLoad={handleImageLoading}
                src={image}
                alt=""
              />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Mission;
