import React from "react";
import Container from "components/Container/Container";

import { ReactComponent as FBICON } from "assests/image/Landing/SVG/f1.svg";
import { ReactComponent as INSTICON } from "assests/image/Landing/SVG/in.svg";

const ContactBox = () => {
  return (
    <div className="">
      <Container>
        <div>
          <h2 className="text-3xl font-philo ">Contact Info</h2>
          <p className="font-cairo">Dubai | Abu Dhabi | Al Ain</p>
        </div>

        <div className="flex flex-col gap-4 mt-6">
          <a
            className="font-cairo text-lg flex items-center gap-x-1"
            href="mailto:info@gsinnovators.com"
          >
            <span className="font-semibold text-primary">Email: </span>
            <p className="hover:underline">info@gsinnovators.com</p>
          </a>
          <a
            className="font-cairo text-lg flex items-center gap-x-1 "
            href="tel:+971506890569"
          >
            <span className="font-semibold text-primary ">Tel: {""}</span>
            <p className="hover:underline">+971 50 689 0569</p>
          </a>
        </div>

        <div className="flex flex-col gap-3 mt-6">
          <h4 className="text-3xl font-philo">Stay Tunned</h4>
          <div className="flex items-center gap-x-2">
            <span className="flex items-center gap-x-2 bg-primary w-max p-1 h-full rounded-full transition ease-in duration-300 hover:scale-[1.04] block">
              <a
                target="_blank"
                without
                rel="noreferrer"
                href="https://www.facebook.com/gsinnovatorsuae/"
              >
                <FBICON width="24" height="24" fill="white" />
              </a>
            </span>
            <span className="flex items-center gap-x-2 bg-primary w-max p-1 h-full rounded-full transition ease-in duration-300 hover:scale-[1.04] block">
              <a
                target="_blank"
                without
                rel="noreferrer"
                href="https://www.instagram.com/gsinnovators/"
              >
                <INSTICON width="24" height="24" fill="white" />
              </a>
            </span>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ContactBox;
