import { HomeProvider } from "./Home/HomeContext";
import { ServicesProvider } from "./Services/ServicesContext";
import { BlogProvider } from "./Blog/BlogContext";
import { AboutProvider } from "./About/AboutContext";
import { ProjectProvider } from "./Project/ProjectContext";

const ContextProvider = ({ children }) => {
  return (
    <HomeProvider>
      <ServicesProvider>
        <BlogProvider>
          <AboutProvider>
            <ProjectProvider>{children}</ProjectProvider>
          </AboutProvider>
        </BlogProvider>
      </ServicesProvider>
    </HomeProvider>
  );
};

export default ContextProvider;
