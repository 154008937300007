import React from "react";

const LandingTab = ({ servicesData, activeTab, onMouseHandler }) => {
  return (
    <div className="hidden lg:flex relative w-full z-[100] lg:w-[40%]  flex-row items-strart justify-center flex-wrap lg:flex-nowrap gap-3 lg:flex-col lg:items-end lg:justify-end px-4">
      {servicesData?.services?.map((service, index) => (
        <button
          key={index}
          className={`text-xl xll:text-3xl  lg:text-end border-b pb-2 transition ease-in duration-300 ${
            index === activeTab
              ? "active border-[#dddddd9e] text-white"
              : "border-transparent text-[#dddddd9e]"
          }`}
          onMouseEnter={() => onMouseHandler(index)}
        >
          {service.title}
        </button>
      ))}
    </div>
  );
};

export default LandingTab;
