import Container from "components/Container/Container";
// components
import FooterLogo from "./Components/footerLogo/FooterLogo";
import FooterLink from "./Components/FooterLink/FooterLink";
import FooterContact from "./Components/FooterContact/FooterContact";
import FooterCTA from "./Components/FooterCTA/FooterCTA";
const Footer = () => {
  return (
    <footer className="relative z-[1000] bg-white border-t border-black mt-secondary lg:mt-primary drop-shadow-2xl">
      <Container>
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 w-full gap-10 lg:gap-14 py-14 lg:py-24 ">
          <FooterLogo />
          <FooterLink />
          <FooterContact />
          <FooterCTA />
        </div>
      </Container>
      <div className="bg-black pt-4 py-10">
        <p className="text-white text-center text-sm md:text-base">
          © All rights reserved. Powered by{" "}
          <a
            className="underline"
            rel="noreferrer"
            target="_blank"
            href="https://www.dowgroup.com/"
          >
            Dow Group
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
