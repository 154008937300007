// images
import React, { useState, useEffect, useContext } from "react";

import Container from "components/Container/Container";
import ImageSkelton from "components/UI/ImageSkelton";

// context
import { HomeContext } from "context/Home/HomeContext";
import IsLoading from "components/UI/IsLoading";
import IsError from "components/UI/IsError";
const MobileLanding = () => {
  const { fetchData, homeData, isLoading, error } = useContext(HomeContext);
  const [imageLoading, setImageLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const image = homeData?.image;
  const videos = homeData?.video;
  const header = homeData?.Header;

  const handleImageLoading = () => {
    setImageLoading(false);
  };

  const Image = ({ src }) => {
    return (
      <>
        {imageLoading && <ImageSkelton />}
        <img
          src={src}
          alt=""
          onLoad={handleImageLoading}
          className={`w-full h-full object-cover ${
            imageLoading ? "hidden" : "block"
          }`}
        />
      </>
    );
  };

  return (
    <section className="lg:hidden py-secondary">
      {isLoading && (
        <div className="h-[30vh]">
          <Container>
            <IsLoading />
          </Container>
        </div>
      )}
      {error && (
        <div className="h-[30vh]">
          <Container>
            <IsError />
          </Container>
        </div>
      )}
      {!isLoading && !error && (
        <Container>
          <div className="flex gap-x-3 h-[14rem]">
            <div className="w-full h-full">
              <Image src={image?.[0]} />
            </div>
            <div className="w-full h-full">
              <Image src={image?.[1]} />
            </div>
          </div>
          {/* titke */}
          <div className={`flex flex-col text-end font-philo my-10 pr-4`}>
            {" "}
            <span className="text-primary text-lg">
              {" "}
              {header?.[0].smalltext}
            </span>
            <span className="text-5xl  md:text-6xl lg:text-7xl xll:text-8xl uppercase">
              {header?.[0].title}
            </span>
            <span className="text-2xl md:text-4xl lg:text-5xl xll:text-6xl">
              {header?.[0].subTitle}
            </span>
          </div>

          <div className="flex gap-x-3 h-[22rem]">
            <div className="w-[40%] h-[60%]">
              <Image src={image?.[2]} />
            </div>
            <div className="w-[60%] h-full">
              <video
                className="w-full h-[22rem] object-cover"
                autoPlay
                loop
                muted
                playsInline
                loading={"lazy"}
                webkit-playsinline="true"
                controlsList="nofullscreen"
              >
                <source src={videos?.[0]} type="video/mp4" />
                Sorry, your browser doesn't support embedded videos.
              </video>
            </div>
          </div>

          {/* titke */}
          <div className={`flex flex-col text-end font-philo my-10 pr-4`}>
            <span className="text-2xl md:text-4xl lg:text-5xl xll:text-6xl">
              {header?.[1].subTitle}
            </span>
            <span className="text-5xl md:text-6xl lg:text-7xl xll:text-8xl uppercase">
              {header?.[1].title}
            </span>
            <span className="text-primary text-lg uppercase">
              {" "}
              {header?.[1].smalltext}
            </span>
          </div>

          <div className="flex gap-x-3 h-[22rem]">
            <div className="w-[60%] h-full">
              <video
                className="w-full h-[22rem] object-cover"
                autoPlay
                loop
                muted
                playsInline
                loading={"lazy"}
                webkit-playsinline="true"
                controlsList="nofullscreen"
              >
                <source src={videos?.[1]} type="video/mp4" />
                Sorry, your browser doesn't support embedded videos.
              </video>
            </div>
            <div className="w-[40%] h-[60%]">
              <Image src={image?.[4]} />
            </div>
          </div>
        </Container>
      )}
    </section>
  );
};

export default MobileLanding;
