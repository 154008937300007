import React, { useState, useEffect, useRef, useContext } from "react";
import { ProjectContext } from "context/Project/ProjectContext";
import Slider from "components/Slider/Slider";
import IsLoading from "components/UI/IsLoading";
import IsError from "components/UI/IsError";
import { Link } from "react-router-dom";

const Projects = () => {
  const [isVisible, setIsVisible] = useState(false);
  const targetRef = useRef(null);

  // fetching data
  const { getProjectData, projectData, isLoading, error } =
    useContext(ProjectContext);

  useEffect(() => {
    if (isVisible) {
      getProjectData("");
    }
  }, [isVisible, getProjectData]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
        if (entry.isIntersecting) {
          // Stop tracking when the element is in view
          observer.unobserve(targetRef.current);
        }
      },
      {
        threshold: 0,
      }
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [targetRef, setIsVisible]);

  // Randomly retrieve 10 projects
  // Retrieve the latest 10 projects based on the created_at property
  const getLatestProjects = () => {
    if (!projectData || !projectData.projects) {
      return [];
    }

    // Sort projects by created_at timestamp in descending order
    const sortedProjects = projectData.projects.sort((a, b) => {
      return new Date(b.created_at) - new Date(a.created_at);
    });

    // Return the latest 10 projects
    return sortedProjects.slice(0, 10);
  };

  const latestProjects = getLatestProjects();

  return (
    <div ref={targetRef} className=" bg-black relative z-[1000]">
      {isLoading && (
        <div className="h-[100vh] text-white relative z-[1000]   flex items-center justify-center">
          <IsLoading />
        </div>
      )}
      {error && (
        <div className="h-[100vh] text-white relative z-[1000]   flex items-center justify-center">
          <IsError iswhite={true} />
        </div>
      )}

      {!isLoading && !error && (
        <>
          <Slider data={latestProjects} title={"Our Projects"} />
          <div className="flex items-center justify-center relative z-[1000] lg:-mt-32 pb-20">
            <Link
              className="text-white border-primary border px-6 py-2 text-lg font-cairo text-center rounded-[22px] transition ease-in duration-300 hover:bg-primary hover:text-white"
              to={"/project"}
            >
              All Projects
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default Projects;
