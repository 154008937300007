const CatBox = ({ data, onClick, selectedCategorie }) => {
  return (
    <div className="flex-1 bg-gray-200 p-10 h-max rounded-tl-[33px] list-style">
      <h6 className="font-philo font-medium text-3xl mb-2">Categories</h6>
      <ul className="flex flex-wrap lg:flex-nowrap lg:flex-col gap-3 mt-6 ">
        <li>
          <button
            onClick={() => onClick(null)}
            className={`text-lg  font-cairo w-max ${
              selectedCategorie === null
                ? "text-primary font-medium  "
                : "text-greyFont"
            }`}
          >
            All
          </button>
        </li>
        {data?.blogs?.map(({ category }, index) => (
          <li key={index}>
            <button
              onClick={() => onClick(category)}
              className={` text-lg font-cairo w-max ${
                selectedCategorie === category
                  ? "text-primary font-medium "
                  : "text-greyFont"
              }`}
            >
              {category}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CatBox;
