import { List, X } from "@phosphor-icons/react";
import { useState, useEffect } from "react";
import headerLinks from "constant/Header/HeaderLinks";
import { Link } from "react-router-dom";

const NavigationButton = () => {
  const [isNavActive, setIsNavActive] = useState(false);
  const handleNavigationActive = () => {
    setIsNavActive((cur) => !cur);
  };

  return (
    <div>
      <button
        onClick={handleNavigationActive}
        className={`flex items-center gap-x-2  z-[200] ${
          isNavActive ? "fixed" : "relative"
        }`}
      >
        <p
          className={`uppercase font-bold tracking-wide transition ease-in duration-300  font-cairo ${
            !isNavActive ? "text-black" : "text-white"
          }`}
        >
          {!isNavActive && "Menu"}
          {isNavActive && "Close"}
        </p>
        <span className={`${!isNavActive ? "text-primary" : "text-white"}`}>
          {!isNavActive && <List size={26} />}
          {isNavActive && <X size={26} />}
        </span>
      </button>

      <div
        className={` left-0 top-0 w-[100vw] h-[100vh] bg-primary z-[100] flex items-center justify-center transition ease-in duration-500 ${
          !isNavActive
            ? " absolute top-0 -translate-y-[100%] -translate-x-[100%] scale-[0] opacity-0 select-none invisible"
            : "fixed translate-y-[0] translate-x-[0%] scale-[1] opacity-100 select-auto visible"
        }`}
      >
        <ul
          className={`flex flex-col text-center font-philo uppercase gap-y-6 transition ease-in duration-700 ${
            !isNavActive
              ? "scale-[0]  -translate-y-[100%] opacity-0"
              : "scale-[1] translate-y-[0%] opacity-100"
          }`}
        >
          {headerLinks.map(({ link, to }, index) => (
            <li
              onClick={handleNavigationActive}
              className="text-2xl transition ease-in duration-300 hover:underline text-[#ccc] hover:text-white"
              key={index}
            >
              <Link to={to}>{link}</Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default NavigationButton;
