import { Link } from "react-router-dom";
import {
  LinkedinLogo,
  FacebookLogo,
  InstagramLogo,
} from "@phosphor-icons/react";

const Contact = () => {
  return (
    <div className="hidden md:flex items-center gap-x-6 relative ">
      <span className="flex items-center gap-x-3">
        <a
          className="hover:scale-[1.05] block transition ease-in duration-100"
          target="_blank"
          rel="noreferrer"
          href="https://www.facebook.com/gsinnovatorsuae/"
        >
          <FacebookLogo size={24} />
        </a>
        <a
          className="hover:scale-[1.05] block transition ease-in duration-100"
          target="_blank"
          rel="noreferrer"
          href="https://www.instagram.com/gsinnovators/"
        >
          <InstagramLogo size={24} />
        </a>
        <a
          className="hover:scale-[1.05] block transition ease-in duration-100"
          target="_blank"
          rel="noreferrer"
          href="https://www.linkedin.com/company/gs-innovators/?viewAsMember=true"
        >
          <LinkedinLogo size={24} />
        </a>
        <a
          className="hover:scale-[1.05] block transition ease-in duration-100"
          target="_blank"
          rel="noreferrer"
          href="https://twitter.com/GsInnovators"
        >
          <div className="border-[1.6px] p-[0.8px] border-black rounded-sm">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="15"
              height="15"
            >
              <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
            </svg>
          </div>
        </a>
      </span>
      <button className="px-6 py-2 rounded-[22px] border border-primary cursor-pointer transition ease-in duration-300 hover:bg-primary hover:text-white h-max w-full font-cairo bg-transparent">
        <Link to={"/contact-us"}>Contact us</Link>
      </button>
    </div>
  );
};

export default Contact;
