import { Swiper, SwiperSlide } from "swiper/react";
import { CaretRight } from "@phosphor-icons/react";
// Import Swiper styles
import "swiper/css";

import "./styles.css";
const MobileTab = ({ servicesData, activeTab, onMouseHandler }) => {
  return (
    <div className="lg:hidden relative ">
      <Swiper
        slidesPerView={"auto"}
        spaceBetween={20}
        className="services-slider"
      >
        {servicesData?.services?.map((service, index) => (
          <SwiperSlide key={index} className="min-w-max  ">
            <div
              className={`text-xl xll:text-3xl  lg:text-end border-b   min-w-max h-[2rem] ${
                index === activeTab
                  ? "active border-[#dddddd9e] text-white"
                  : "border-transparent text-[#dddddd9e]"
              }`}
              onClick={() => onMouseHandler(index)}
            >
              {service.title}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="absolute top-0 right-0 animate-pulse  h-full flex items-center justify-center">
        <CaretRight color="#fff" size={26} weight="bold" />
      </div>
    </div>
  );
};

export default MobileTab;
