import React, { createContext, useState, useCallback } from "react";
import getData from "helpers/GetContent";

export const ServicesContext = createContext();

export const ServicesProvider = ({ children }) => {
  const [servicesData, setServicesData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const getServicesData = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(false);

      const data = await getData("services");
      setServicesData(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchData = useCallback(() => {
    getServicesData();
  }, [getServicesData]);

  return (
    <ServicesContext.Provider
      value={{ fetchData, servicesData, isLoading, error }}
    >
      {children}
    </ServicesContext.Provider>
  );
};
