import React, { useState, useEffect, useRef, useContext } from "react";
// components
import LandingHeader from "./components/LandingHeader";
import ImageSkelton from "components/UI/ImageSkelton";
// animation
import { useSpring, animated } from "react-spring";

// context
import { HomeContext } from "context/Home/HomeContext";
import videoPlaceholder from "assests/image/loading-video.webp";
const Landing = () => {
  const [scrollY, setScrollY] = useState(0);
  const [visibleSection, setVisibleSection] = useState(null);
  const parentRef = useRef(null);

  const { fetchData, homeData, isLoading, error } = useContext(HomeContext);

  useEffect(() => {
    fetchData();
  }, []);

  const image = homeData?.image;
  const videos = homeData?.video;
  const header = homeData?.Header;

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "",
      threshold: 0.6,
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    document.querySelectorAll(".parent > div").forEach((child) => {
      observer.observe(child);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // Update state with the id or index of the visible section
        setVisibleSection(() => entry.target.id); // Assuming each section has a unique id
      }
    });
  };
  // spring
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const useParallaxSpring = (scrollY, divisor) => {
    return useSpring({
      to: { transform: `translate3d(0, ${-scrollY / divisor}px, 0)` },
      config: { tension: 60, friction: 20 },
    });
  };

  const [imageLoading, setImageLoading] = useState(true);
  const [videoLoadingTwo, setVideoLoadingTwo] = useState(true);
  const [videoLoading, setVideoLoading] = useState(true);

  const handleImageLoading = () => {
    setImageLoading(false);
  };

  const handleVideoLoadingTwo = () => {
    setVideoLoadingTwo(false);
  };
  const handleVideoLoading = () => {
    setVideoLoading(false);
  };

  const Image = ({ src, style, absolute }) => {
    return (
      <>
        {imageLoading && <ImageSkelton />}
        <animated.img
          style={style}
          src={src}
          onLoad={handleImageLoading}
          className={`${
            absolute
              ? "absolute bottom-20 -left-44 w-64 xll:w-80 h-64 max-h-full  z-[5]"
              : " w-full  max-h-[36rem] object-contain  z-[5]"
          } ${imageLoading ? "hidden" : "block"}`}
        />
      </>
    );
  };

  // first
  const Fi1 = useParallaxSpring(scrollY, 1.6);
  const Fi2 = useParallaxSpring(scrollY, 2);
  const Fi3 = useParallaxSpring(scrollY, 2);

  // second
  const Si1 = useParallaxSpring(scrollY, 1.6);
  const Si2 = useParallaxSpring(scrollY, 2);
  const fastImageFirstRow = useParallaxSpring(scrollY, 0.8);

  // third
  const Ti1 = useParallaxSpring(scrollY, 2);
  const Ti2 = useParallaxSpring(scrollY, 2);

  // fourth
  const FOi1 = useParallaxSpring(scrollY, 1.6);
  const FOi2 = useParallaxSpring(scrollY, 2);
  const fastImageSecondRow = useParallaxSpring(scrollY, 1);

  return (
    <section className="hidden lg:block relative overflow-hidden  ">
      <div ref={parentRef} className={`parent h-[475vh] relative  `}>
        <LandingHeader
          error={error}
          content={header}
          headerIs={visibleSection}
        />

        {/* first row */}
        <div id="firstRow" className="h-[100vh] flex mt-24 justify-between ">
          <div className="w-[24rem] xl:w-[27rem] xll:w-[30rem] max-h-[36rem]">
            <Image src={image?.[0]} style={Fi1} />
          </div>
          <div className="flex w-[24rem] xl:w-[27rem] xll:w-[30rem] max-h-[36rem] ">
            <Image src={image?.[1]} style={Fi2} />
            {/* <Image src={image?.[2]} style={Fi3} /> */}
          </div>
        </div>

        {/* second row */}
        <div
          id="thirdRow"
          className=" h-[125vh] flex items-center justify-between"
        >
          <div className="w-[24rem] xl:w-[27rem] xll:w-[30rem] max-h-[36rem]">
            <Image style={Si1} src={image?.[2]} />
          </div>
          <div className="w-[24rem]  xll:w-[28rem] max-h-[36rem] mr-16 relative">
            <Image src={image?.[3]} style={fastImageFirstRow} absolute={true} />

            <animated.video
              style={Si2}
              className={`min-w-[26rem]  object-cover z-[5] max-h-[36rem] `}
              autoPlay
              loop
              poster={videoPlaceholder}
              muted
              playsInline
              webkit-playsinline="true"
              controlsList="nofullscreen"
            >
              <source src={videos?.[0]} type="video/mp4" />
              Sorry, your browser doesn't support embedded videos.
            </animated.video>
          </div>
        </div>

        {/* third row */}
        <div
          id="thirdRow"
          className=" h-[125vh] flex items-center justify-between "
        >
          <div className="w-[24rem] xl:w-[27rem] xll:w-[30rem] max-h-[36rem]">
            <Image src={image?.[4]} style={Ti1} />
          </div>
          <div className="w-[24rem] xl:w-[27rem] xll:w-[30rem] max-h-[36rem]">
            <Image src={image?.[5]} style={Ti2} />
          </div>
        </div>

        {/* fourth row */}
        <div
          id="fourth"
          className="h-[125vh] flex items-center justify-between"
        >
          <div className="w-[24rem] xl:w-[27rem] xll:w-[30rem] max-h-[36rem]">
            <Image style={FOi1} src={image?.[6]} />
          </div>
          <div className="w-[24rem] h-[34rem] xll:w-[28rem] xll:h-[36rem] relative mr-16">
            <Image
              src={image?.[7]}
              style={fastImageSecondRow}
              absolute={true}
            />

            <animated.video
              style={FOi2}
              className={`min-w-[26rem] max-h-[36rem] object-cover z-[5] `}
              autoPlay
              loop
              poster={videoPlaceholder}
              muted
              playsInline
              webkit-playsinline="true"
              controlsList="nofullscreen"
            >
              <source src={videos?.[1]} type="video/mp4" />
              Sorry, your browser doesn't support embedded videos.
            </animated.video>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Landing;
