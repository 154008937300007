import Container from "components/Container/Container";
// components
import Logo from "./components/Logo/Logo";
import NavigationButton from "./components/Navigation/NavigationButton";
import Contact from "./components/Contact/Contact";

const Header = () => {
  return (
    <header
      className={`w-full py-3 lg:py-6 transition ease-in duration-300 relative z-[200] `}
    >
      <Container>
        <div className="flex flex-row-reverse md:flex-row items-center md:items-start justify-between">
          <NavigationButton />
          <Logo />
          <Contact />
        </div>
      </Container>
    </header>
  );
};

export default Header;
