import { Link } from "react-router-dom";
import React, { useState } from "react";
import ImageSkelton from "components/UI/ImageSkelton";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./Style.css";

// import required modules
import { Pagination } from "swiper/modules";

const Slider = ({ data, title }) => {
  const [imageIsLoading, setImageIsLoading] = useState(true);
  const handleImageLoading = () => {
    setImageIsLoading(false);
  };

  return (
    <div className="py-14 lg:py-44 relative  flex flex-col lg:items-center justify-center   ">
      <h2 className="lg:absolute lg:-left-10 lg:-top-14 text-[3.8rem] sm:text-[4.8rem] md:text-[6rem] lg:text-[11rem] xl:text-[13rem] z-[100] font-philo text-white red-stroke opacity-75 ">
        {title}
      </h2>

      <Swiper
        pagination={{
          dynamicBullets: true,
          clickable: true,
        }}
        modules={[Pagination]}
        slidesPerView={1}
        spaceBetween={30}
        className="mySwiper"
        breakpoints={{
          578: {
            slidesPerView: 2,
          },

          992: {
            slidesPerView: 3,
          },
        }}
      >
        {data?.map((slide, index) => (
          <SwiperSlide key={index} className="bg-black ">
            <Link
              className={"block bg-black flex flex-col gap-y-2 h-full"}
              to={`${slide.slug ? `/project/${slide.slug}` : ""}`}
            >
              {imageIsLoading && (
                <div className="aspect-square">
                  <ImageSkelton />
                </div>
              )}
              <div className={""}>
                {slide.image ? (
                  <img
                    src={slide.image}
                    alt=""
                    onLoad={handleImageLoading}
                    className={`h-full w-full object-cover  aspect-[1.2/1] ${
                      imageIsLoading ? "hidden" : "block"
                    }`}
                  />
                ) : (
                  <img
                    src={slide}
                    alt=""
                    onLoad={handleImageLoading}
                    className={`h-full w-full object-cover aspect-square  ${
                      imageIsLoading ? "hidden" : "block"
                    }`}
                  />
                )}
              </div>

              {!imageIsLoading && (
                <span className="text-white bg-black flex flex-col">
                  <p className=" text-2xl text-[#bbb] font-light mb-1 font-cairo">
                    {slide?.title}
                  </p>
                  <div className="flex items-center flex-wrap  justify-center text-center gap-x-2">
                    {slide?.providerServices?.map(
                      (service, innerIndex, array) => {
                        const truncatedService =
                          service.length > 12
                            ? `${service.substring(0, 10)}...`
                            : service;

                        return (
                          <React.Fragment key={innerIndex}>
                            <p className="text-center font-cairo">
                              {truncatedService}
                            </p>
                            {innerIndex < array.length - 1 && <span>/</span>}
                          </React.Fragment>
                        );
                      }
                    )}
                  </div>
                </span>
              )}
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Slider;
