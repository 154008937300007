import BASE_URL from "./Base_Url";

const getHeaders = () => ({
  "Content-Type": "application/json",
});

const getData = async (page, optionalSlug = "") => {
  let url = `${BASE_URL}/${page}`;

  if (optionalSlug) {
    url += `/${optionalSlug}`;
  }

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: getHeaders(),
    });

    if (!response.ok) {
      console.error(
        `Failed to perform GET request for  Status: ${response.status}`
      );
      throw new Error(`Failed to perform GET request `);
    }

    return response.json();
  } catch (error) {
    console.error(`Failed to perform GET request . Error: ${error.message}`);
    throw new Error(`Failed to perform GET request `);
  }
};

export default getData;
