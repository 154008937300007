import Landing from "./Landing/Landing";
import BigTitle from "components/UI/BigTitle";
import ValueIndicators from "./ValueIndicators/ValueIndicators";
import Projects from "pages/Home/Project/Projects";
import { Helmet, HelmetProvider } from "react-helmet-async";

const About = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Your leading Fit-out Contractor in UAE: GS Innovators</title>
        <meta
          name="description"
          content="We are considered among top fit out consultants in Abu Dhabi. We provide solutions for retail shops fit out, offices fit outs, and restaurants fit outs in Abu Dhabi."
        />
      </Helmet>
      <main>
        <BigTitle title={"about us"} />
        <Landing />
        <ValueIndicators />
        <Projects />
      </main>
    </HelmetProvider>
  );
};

export default About;
