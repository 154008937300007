import React, { useState, useEffect, useRef, useContext } from "react";

import ImageSkelton from "components/UI/ImageSkelton";

const ImageServices = ({ servicesData, activeTab }) => {
  const [imageIsLoaded, setImageIsLoaded] = useState(true);
  const handleImageLoading = () => {
    setImageIsLoaded(false);
  };
  return (
    <div className="w-full lg:w-[60%] h-full ">
      <div className="image-container inline-flex h-full w-full relative overflow-hidden">
        {imageIsLoaded && <ImageSkelton />}
        {servicesData?.services?.map((tab, index) => (
          <img
            key={index}
            src={tab.image[0]}
            onLoad={handleImageLoading}
            alt={tab.tab}
            className={`object-cover min-w-full min-h-full absolute left-0 top-0 z-[1] lg:rounded-tl-[99px] transition ease-in duration-500  ${
              activeTab === index ? " opacity-1" : " opacity-0"
            } ${imageIsLoaded ? "hidden" : "block"}`}
          />
        ))}
      </div>
    </div>
  );
};

export default ImageServices;
