import React, { useEffect, useState } from "react";
import ImageSkelton from "components/UI/ImageSkelton";
import Container from "components/Container/Container";
import { useParams, useNavigate } from "react-router-dom";
import { CaretLeft } from "@phosphor-icons/react";
import getData from "helpers/GetContent";
import BigTitle from "components/UI/BigTitle";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Spinner from "components/UI/Spinner";

// import
const ServicesDisplay = () => {
  const [imageLoading, setImageLoading] = useState(true);
  const [servicesData, setServicesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleImageLoading = () => {
    setImageLoading(false);
  };

  const { slug } = useParams();
  useEffect(() => {
    const getServicesData = async () => {
      try {
        // Set loading to true when the data fetching starts
        setLoading(true);

        const servicesData = await getData(`services/${slug}`);
        setServicesData(servicesData?.services);
      } catch (error) {
        // Handle any errors here
        console.error("Error fetching services data:", error);
      } finally {
        // Set loading to false regardless of success or failure
        setLoading(false);
      }
    };

    getServicesData();
  }, [slug]);

  console.log(servicesData?.[0]?.meta_description);

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>{servicesData?.[0]?.meta_title}</title>
        <meta
          name="description"
          content={servicesData?.[0]?.meta_description}
        />
        <meta name="keywords" content={servicesData?.[0]?.meta_keywords} />
      </Helmet>
      <div className="flex-grow">
        <BigTitle title={"Our Services"} />
        {loading && (
          <div className="flex items-center justify-center mt-24 gap-x-2">
            <Spinner />
            <p className="font-philo text-lg">Loading...</p>
          </div>
        )}
        {!loading && (
          <Container>
            <div className="flex flex-col gap-10 lg:gap-primary px-[1.4rem] lg:px-[0] relative">
              <div className="flex flex-col lg:first:mt-secondary lg:items-center pt-4 lg:flex-row lg:even:flex-row-reverse gap-6 lg:gap-secondary ">
                <div
                  className={`lg:w-[45%]  
         `}
                >
                  <button
                    onClick={goBack}
                    className="text-lg font-semibold mb-4 flex items-center hover:text-primary"
                  >
                    <CaretLeft weight="bold" size={20} />
                    <p>Return to services</p>
                  </button>
                  <h4 className="text-primary text-2xl sm:text-3xl md:text-4xl font-philo">
                    {servicesData?.[0]?.title}
                  </h4>
                  <p className="my-2 lg:my-4 md:text-xl font-cairo">
                    {servicesData?.[0]?.text}
                  </p>
                  <div
                    className="list-style font-cairo"
                    dangerouslySetInnerHTML={{
                      __html: servicesData?.[0]?.list,
                    }}
                  ></div>
                </div>

                <div
                  className={`lg:w-[55%] flex flex-col md:flex-row gap-2 overflow-hidden lg:max-h-[450px] lg:min-h-[450px]
              
              `}
                >
                  {servicesData?.[0]?.image?.map((img, innerIndex) => (
                    <div
                      key={innerIndex}
                      className={`${
                        innerIndex === 1 ? "lg:w-[55%]" : "lg:w-[45%]"
                      }`}
                    >
                      {imageLoading && <ImageSkelton />}
                      <img
                        className={`object-cover w-full h-full ${
                          imageLoading ? "hidden" : "block"
                        }`}
                        onLoad={handleImageLoading}
                        src={img}
                        alt=""
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="mt-secondary">
              <h2 className="font-philo text-3xl font-semibold">
                {servicesData?.[0]?.company.title}
              </h2>

              <p
                className="font-cairo"
                dangerouslySetInnerHTML={{
                  __html: servicesData?.[0]?.company.text,
                }}
              />
            </div>

            <div className="flex flex-col gap-y-10 mt-secondary font-cairo">
              {servicesData?.[0]?.sections?.map(
                ({ title, services }, index) => (
                  <div key={index}>
                    <h2 className="text-3xl mb-2 font-semibold">{title}:</h2>
                    <div className="flex flex-col gap-y-2">
                      {services?.map((cont, index) => {
                        const parts = cont?.split(":");
                        return (
                          <p key={index}>
                            <span className="service-key font-bold">
                              {parts.length > 1 ? parts[0] + ":" : parts[0]}
                            </span>
                            <span className="service-value">
                              {parts.slice(1).join(":")}
                            </span>
                          </p>
                        );
                      })}
                    </div>
                  </div>
                )
              )}
            </div>
          </Container>
        )}
      </div>
    </HelmetProvider>
  );
};

export default ServicesDisplay;
