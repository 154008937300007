import React from "react";
import { Link } from "react-router-dom";
import scrollToTop from "hooks/ScrollToTop";
const FooterCTA = () => {
  return (
    <div className="col-span-2 md:col-span-1 lg:col-span-2 lg:ml-6">
      <h2 className="font-philo text-4xl md:text-3xl lg:text-4xl xll:text-5xl ">
        Let us create a <br className="hidden lg:block" />
        stunning place
      </h2>

      <button className="px-4 xll:px-4 py-2  xll:text-lg text-white bg-black border border-transparent transition ease-in duration-300 mt-4 text-start capitalize hover:bg-white  hover:border-black hover:text-black">
        <Link onClick={scrollToTop} to={"/contact-us"}>
          send us now
        </Link>
      </button>
    </div>
  );
};

export default FooterCTA;
