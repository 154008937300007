import IsLoading from "./IsLoading";
import IsError from "./IsError";

const FetchHandling = ({ isLoading, isError }) => {
  return (
    <>
      {isLoading && (
        <div className="mt-14 flex items-center justify-center">
          <IsLoading />
        </div>
      )}
      {isError && (
        <div className="mt-14 flex items-center justify-center">
          <IsError />
        </div>
      )}
    </>
  );
};

export default FetchHandling;
