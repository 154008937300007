import { useEffect, useState } from "react";
import "./HeaderStyle.css";

const LandingHeader = ({ headerIs, content, error }) => {
  const [placeHeader, setPlaceHeader] = useState("");

  useEffect(() => {
    setPlaceHeader(headerIs);
  }, [headerIs]);

  return (
    <div className="fixed top-0 left-0 w-full h-[100vh]  flex items-center z-[10]">
      <h1 className={`text-6xl font-philo w-[60%] relative overflow-hiden`}>
        {error ? (
          <div className="flex text-3xl  flex-col text-end">
            Failed to get data, <br />
            please try again.
          </div>
        ) : (
          <>
            <div
              className={`flex  flex-col text-end   ${
                placeHeader === "firstRow"
                  ? "opacity-100 transition ease-in duration-[1.2s] relative "
                  : "absolute right-0 top-0  opacity-0 transition ease-in-out duration-[1.2s]"
              }`}
            >
              {" "}
              <span className="text-primary text-lg ">
                {content?.[0].smalltext}
              </span>
              <span className="text-7xl xll:text-8xl uppercase">
                {content?.[0].title}
              </span>
              <span className="text-5xl xll:text-6xl">
                {content?.[0].subTitle}
              </span>
            </div>

            <div
              className={`flex flex-col text-end    ${
                placeHeader === "thirdRow"
                  ? "opacity-100 transition ease-in duration-[1.2s] relative "
                  : "absolute right-0 top-0  opacity-0 transition ease-in-out duration-[1.2s]"
              }`}
            >
              <span className="text-5xl xll:text-6xl">
                {content?.[1].subTitle}
              </span>
              <span className="text-7xl xll:text-8xl uppercase">
                {" "}
                {content?.[1].title}
              </span>
              <span className="text-primary text-lg uppercase">
                {" "}
                {content?.[1].smalltext}
              </span>
            </div>
          </>
        )}
      </h1>
    </div>
  );
};

export default LandingHeader;
