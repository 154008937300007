import React from "react";

const Textarea = (props) => {
  const {
    label,
    name,
    id,
    placeholder,
    rows,
    value,
    onChange = () => {},
    onBlur = () => {},
    hasError = false,
    errorMessage = "error",
  } = props;
  return (
    <span className="flex flex-col w-full">
      <label className="text-white text-lg">{label}</label>
      <textarea
        className={`bg-lightBlue p-2 w-full text-black font-cairo font-medium ${
          hasError ? "border-2 border-primary" : ""
        }`}
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        rows={6}
        wrap="soft"
      />
      <p className={`text-primary ${hasError ? "opacity-1 " : "opacity-0"}`}>
        {errorMessage}
      </p>
    </span>
  );
};

export default Textarea;
