import React from "react";

const ServicesHeader = () => {
  return (
    <h2 className="lg:absolute lg:-left-14 lg:top-0 text-[3.8rem] sm:text-[4.8rem] md:text-[6rem] lg:text-[12rem] xll:text-[14rem] z-[10] font-philo text-white white-stroke opacity-50 ">
      Our Services
    </h2>
  );
};

export default ServicesHeader;
