import React, { createContext, useState, useCallback } from "react";
import getData from "helpers/GetContent";

export const HomeContext = createContext();

export const HomeProvider = ({ children }) => {
  const [homeData, setHomeData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const getHomeData = useCallback(async () => {
    try {
      setError(false);
      setIsLoading(true);
      const data = await getData("home");
      setHomeData(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchData = useCallback(() => {
    getHomeData();
  }, [getHomeData]);

  return (
    <HomeContext.Provider value={{ fetchData, homeData, isLoading, error }}>
      {children}
    </HomeContext.Provider>
  );
};
