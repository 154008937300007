import { useNavigate, useLocation } from "react-router-dom";
import Container from "components/Container/Container";
import { ArrowLeft } from "@phosphor-icons/react";
const BlogDetailed = () => {
  const location = useLocation();
  const data = location.state.data;
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <main>
      <div className="bg-gray-300 pt-10 py-28">
        <Container>
          <div>
            <button
              onClick={handleGoBack}
              className="flex items-center gap-x-2 mb-2"
            >
              {" "}
              <ArrowLeft size={18} weight="light" />
              <p className="font-philo text-[#adafb2] text-2xl"> Blog</p>
            </button>
            <h1 className="text-4xl font-philo">{data.title}</h1>
          </div>
        </Container>
      </div>

      <Container className={"-mt-20"}>
        <div className="lg:w-3/4 mx-auto">
          <img className="w-full" src={data.image} alt="" />
        </div>

        <div className="mt-6 lg:w-3/4 mx-auto flex flex-col gap-6 font- lg:px-10 paragraph">
          <p className="" dangerouslySetInnerHTML={{ __html: data.content }} />
        </div>
      </Container>
    </main>
  );
};

export default BlogDetailed;
