import React, { createContext, useState, useCallback } from "react";
import getData from "helpers/GetContent";

export const AboutContext = createContext();

export const AboutProvider = ({ children }) => {
  const [aboutUsData, setAboutUsData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const getAboutData = useCallback(async () => {
    try {
      setError(false);
      setIsLoading(true);
      const data = await getData("about-us");
      setAboutUsData(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchData = useCallback(() => {
    getAboutData();
  }, [getAboutData]);

  return (
    <AboutContext.Provider value={{ fetchData, aboutUsData, isLoading, error }}>
      {children}
    </AboutContext.Provider>
  );
};
