import React, { useEffect, useContext } from "react";
// UI
import IsLoading from "components/UI/IsLoading";
import IsError from "components/UI/IsError";
import Container from "components/Container/Container";
// components
import WhatWeDo from "./Components/WhatWeDo";
import Mission from "./Components/Mission";
// context
import { AboutContext } from "context/About/AboutContext";
const Landing = () => {
  const { fetchData, aboutUsData, isLoading, error } = useContext(AboutContext);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <section className="mt-4">
      <Container>
        {isLoading && (
          <div className="my-10">
            <IsLoading />
          </div>
        )}
        {error && (
          <div className="my-10">
            <IsError />
          </div>
        )}
      </Container>

      {!isLoading && !error && (
        <>
          <div className="px-[1rem] md:px-[3.1rem] lg:px-0 lg:pl-[3.1rem] xl:pl-[8rem] xll:pl-[10rem]">
            <WhatWeDo content={aboutUsData?.about?.whatWeDo} />
          </div>
          <Container>
            <Mission content={aboutUsData?.about?.values} />
          </Container>
        </>
      )}
    </section>
  );
};

export default Landing;
