import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
// icons
import { ArrowLeft } from "@phosphor-icons/react";
import { ProjectContext } from "context/Project/ProjectContext";
import { useParams } from "react-router-dom";
// UI
import Container from "components/Container/Container";
// content
import Slider from "components/Slider/Slider";
import DetailedProjectSlider from "./DetailedProjectSlider";
import IsLoading from "components/UI/IsLoading";
import IsError from "components/UI/IsError";

const ProjectDetailed = () => {
  const { slug } = useParams();

  // fetching data
  const { getProjectDataSLug, slugProject, isLoading, error } =
    useContext(ProjectContext);

  useEffect(() => {
    getProjectDataSLug(slug);
  }, [slug, getProjectDataSLug]);

  // this is because the data is nested in an array
  const data = slugProject?.projects?.[0] || [];

  return (
    <main className="mt-14">
      {isLoading && (
        <div className="mt-24 flex justify-center  h-[100vh]">
          <IsLoading />
        </div>
      )}
      {error && (
        <div className="mt-24 flex justify-center h-[100vh]">
          <IsError />
        </div>
      )}
      {!isLoading && !error && (
        <Container>
          <div>
            <button>
              <Link className="flex items-center gap-x-2 mb-2 " to="/project">
                {" "}
                <ArrowLeft size={18} weight="light" />
                <p className="font-philo text-greyFont text-lg">Our Projects</p>
              </Link>
            </button>
            <div className="flex flex-col gap-10 sm:items-center sm:flex-row justify-between">
              <div>
                <h1 className="text-3xl md:text-4xl lg:text-5xl mb-2 font-philo">
                  {data?.title}
                </h1>
                <p className="relative w-max text-lg text-greyFont font-cairo after:absolute after:left-0 after:-bottom-1 after:w-1/2 after:bg-primary after:h-[2px]">
                  {data?.category}
                </p>
              </div>
              <div className=" flex flex-col justify-center bg-gray-200 lg:rounded-tl-[42px] p-4 md:p-6 lg:p-10 h-max">
                <h2 className="text-3xl md:text-4xl mb-4 font-philo">
                  Services provider{" "}
                </h2>
                <div className="list-style">
                  <ul>
                    {data?.providerServices?.map((service, index) => (
                      <li
                        className="font-cairo sm:text-lg text-greyFont"
                        key={index}
                      >
                        {service}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Container>
      )}
      <DetailedProjectSlider data={data?.image} />

      {/* <div className="bg-black mt-14">
        <Slider data={data.image} title={"Gallery"} />
      </div> */}
    </main>
  );
};

export default ProjectDetailed;
