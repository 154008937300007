import React from "react";

const BigTitle = ({ title }) => {
  return (
    <div className="">
      {" "}
      <h2 className="text-[4.2rem] md:text-[6rem] lg:text-[10rem] xll:text-[14rem] xll:leading-[1] black-stroke font-philo leading-[1] ">
        {title}
      </h2>
    </div>
  );
};

export default BigTitle;
