import React, { useState } from "react";
import Container from "components/Container/Container";
import Input from "components/Form/Input";
import Textarea from "components/Form/TextArea";
import useInput from "hooks/UserInput";
import BASE_URL from "helpers/Base_Url";
import Spinner from "components/UI/Spinner";

const ContactForm = () => {
  const [formISNotValid, SetFormIsNotValid] = useState(false);
  const [formIsSucces, setFormIsSuccess] = useState(false);
  const [formFailed, setFormFailed] = useState(false);
  const [isLoading, setIsloading] = useState(false);

  const clearError = () => {
    SetFormIsNotValid(false);
  };

  const clearInputsHanlder = () => {
    fullNameReset();
    phoneNumberReset();
    emailReset();
    subjectReset();
    messageReset();
  };
  const {
    value: fullNameInput,
    isValid: fullNameIsValid,
    HasError: fullNameHasError,
    inputChangeHandler: fullNameChangeHandler,
    inputBlurHandler: fullNameBlurHandler,
    inputFocusHandler: fullNameFocusHanlder,
    isFocus: fullNameIsFocus,
    reset: fullNameReset,
  } = useInput((value) => value.trim() !== "");

  const {
    value: phoneNumberInput,
    isValid: phoneNumberIsValid,
    HasError: phoneNumberHasError,
    inputChangeHandler: phoneNumberChangeHandler,
    inputBlurHandler: phoneNumberBlurHandler,
    inputFocusHandler: phoneNumberFocusHanlder,
    isFocus: phoneNumberIsFocus,
    reset: phoneNumberReset,
  } = useInput((value) => value.trim() !== "");

  const {
    value: emailInput,
    isValid: emailIsValid,
    HasError: emailHasError,
    inputChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    inputFocusHandler: emailFocusHanlder,
    isFocus: emailIsFocus,
    reset: emailReset,
  } = useInput((value) => value.trim() !== "");

  const {
    value: subjectInput,
    isValid: subjectIsValid,
    HasError: subjectHasError,
    inputChangeHandler: subjectChangeHandler,
    inputBlurHandler: subjectBlurHandler,
    inputFocusHandler: subjectFocusHanlder,
    isFocus: subjectIsFocus,
    reset: subjectReset,
  } = useInput((value) => value.trim() !== "");

  const {
    value: messageInput,
    isValid: messageIsValid,
    HasError: messageHasError,
    inputChangeHandler: messageChangeHandler,
    inputBlurHandler: messageBlurHandler,
    inputFocusHandler: messageFocusHanlder,
    isFocus: messageIsFocus,
    reset: messageReset,
  } = useInput((value) => value.trim() !== "");

  let formIsValid =
    fullNameIsValid &&
    phoneNumberIsValid &&
    emailIsValid &&
    subjectIsValid &&
    messageIsValid;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formIsValid) {
      SetFormIsNotValid(true);
      return;
    }

    setIsloading(true);
    SetFormIsNotValid(false);
    setFormFailed(false);

    const formData = new FormData();
    formData.append("full_name", fullNameInput);
    formData.append("phone_number", phoneNumberInput);
    formData.append("email", emailInput);
    formData.append("subject", subjectInput);
    formData.append("message", messageInput);

    try {
      const apiUrl = `${BASE_URL}/contact`;
      const response = await fetch(apiUrl, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        const errorMessage = await response.json();
        setFormFailed(true);
      } else {
        const data = await response.json();
        clearInputsHanlder();
        setFormIsSuccess(true);
      }
    } catch (error) {
      setFormFailed(true);
    } finally {
      setIsloading(false);
    }
  };

  return (
    <section className="bg-black mt-secondary mt-primary py-secondary">
      <Container>
        <div className="flex items-center justify-center flex-col">
          <h2 className="text-white text-3xl font-philo">Contact Form</h2>
          {formISNotValid && (
            <p className="text-primary font-cairo">
              Make Sure all Field are valid and try again
            </p>
          )}
          {formFailed && (
            <p className="text-primary font-cairo">
              Failed to submit you form, please try again later
            </p>
          )}

          {formIsSucces && (
            <p className="text-green-400 font-cairo text-center">
              You have succefully submit you form, we will get in touch as soiin
              as opossible
            </p>
          )}
        </div>

        <form className="flex flex-col gap-1  lg:gap-2 mt-10 lg:w-3/4 mx-auto">
          <span className="flex flex-col lg:flex-row items-center gap-x-6">
            <Input
              label={"Full name"}
              type={"text"}
              name={"full-name"}
              id={"full-name"}
              value={fullNameInput}
              onChange={(event) => {
                fullNameChangeHandler(event);
                clearError();
              }}
              onBlur={fullNameBlurHandler}
              hasError={fullNameHasError}
              onFocus={fullNameFocusHanlder}
              isFocus={fullNameIsFocus}
              errorMessage={"This field is required"}
            />
            <Input
              label={"Phone Number"}
              type={"number"}
              name={"phone-number"}
              id={"phone-number"}
              value={phoneNumberInput}
              onChange={(event) => {
                phoneNumberChangeHandler(event);
                clearError();
              }}
              onBlur={phoneNumberBlurHandler}
              hasError={phoneNumberHasError}
              onFocus={phoneNumberFocusHanlder}
              isFocus={phoneNumberIsFocus}
              errorMessage={"This field is required"}
            />
          </span>
          <span className="flex flex-col lg:flex-row items-center gap-x-6">
            <Input
              label={"Email"}
              type={"email"}
              name={"email"}
              id={"email"}
              value={emailInput}
              onChange={(event) => {
                emailChangeHandler(event);
                clearError();
              }}
              onBlur={emailBlurHandler}
              hasError={emailHasError}
              onFocus={emailFocusHanlder}
              isFocus={emailIsFocus}
              errorMessage={"This field is required"}
            />
            <Input
              label={"Subject"}
              type={"text"}
              name={"subject"}
              id={"subject"}
              value={subjectInput}
              onChange={(event) => {
                subjectChangeHandler(event);
                clearError();
              }}
              onBlur={subjectBlurHandler}
              hasError={subjectHasError}
              onFocus={subjectFocusHanlder}
              isFocus={subjectIsFocus}
              errorMessage={"This field is required"}
            />
          </span>
          <span>
            <Textarea
              label={"Message"}
              type={"text"}
              name={"message"}
              id={"message"}
              value={messageInput}
              onChange={messageChangeHandler}
              onBlur={messageBlurHandler}
              hasError={messageHasError}
              onFocus={messageFocusHanlder}
              isFocus={messageIsFocus}
              errorMessage={"This field is required"}
            />
          </span>
        </form>

        <button
          onClick={handleSubmit}
          className="bg-primary text-white font-cairo px-10 py-2 mt-6 w-max mx-auto flex items-center gap-x-2 justifty-center"
        >
          {isLoading && <Spinner />}
          Submit
        </button>
      </Container>
    </section>
  );
};

export default ContactForm;
