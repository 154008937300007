import React, { useEffect, useContext } from "react";
// UI
import Container from "components/Container/Container";
// context
import { AboutContext } from "context/About/AboutContext";
import "./Style.css";

const ValueIndicators = () => {
  const { fetchData, aboutUsData, isLoading, error } = useContext(AboutContext);

  useEffect(() => {
    fetchData();
  }, []);

  const data = aboutUsData?.about?.valuesIndicators;
  const imageUrl = data?.image?.replace(/\\/g, "/");
  return (
    !isLoading &&
    !error && (
      <section className="min-h-[500px] my-secondary lg:my-primary">
        <Container className="min-h-[500px]">
          <div
            className="min-h-[500px] bg-value flex flex-col justify-center "
            style={{ backgroundImage: `url(${imageUrl})` }}
          >
            <div className="bg-[#000000b9] w-max font-philo text-white py-14 pl-7 pr-7 md:pr-32 lg:pl-14 lg:pr-44 rounded-tr-[44px]">
              <h2 className="text-4xl">{data?.list?.title}</h2>
              <div
                className="list-style font-cairo"
                dangerouslySetInnerHTML={{
                  __html: data?.list?.list,
                }}
              ></div>
            </div>
          </div>
        </Container>
      </section>
    )
  );
};

export default ValueIndicators;
