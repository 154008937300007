import { Link } from "react-router-dom";
const FooterLink = () => {
  const links = [
    {
      link: "Home",
      to: "/",
    },
    {
      link: "About us",
      to: "/about-us",
    },
    {
      link: "services",
      to: "/services",
    },
    {
      link: "projects",
      to: "/project",
    },
  ];
  return (
    <div>
      <ul className="flex flex-col col-span-2">
        {links.map(({ link, to }, ix) => (
          <Link
            className="font-medium text-lg transition ease-in duration-100 hover:underline capitalize"
            key={ix}
            to={to}
          >
            {link}
          </Link>
        ))}
      </ul>
    </div>
  );
};

export default FooterLink;
