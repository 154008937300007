import React, { useEffect, useContext, useState } from "react";
// UI
import BigTitle from "components/UI/BigTitle";
import FetchHandling from "components/UI/FetchHandling";
import getData from "helpers/GetContent";

// components
import LandingTabs from "./LandingTabs";
import ServicesDisplay from "./ServicesDisplay";
// context
import { ServicesContext } from "context/Services/ServicesContext";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Services = () => {
  const [activeButton, setActiveButton] = useState(null);
  const [isAtTop, setIsAtTop] = useState(true);
  const [selectedData, setSelctedData] = useState({});
  // context, accessing data
  const { fetchData, servicesData, isLoading, error } =
    useContext(ServicesContext);

  // fetching data
  useEffect(() => {
    fetchData();
  }, []);
  const handleClick = async (slug, id) => {
    const data = await getData(`services/${slug}`);

    setSelctedData(data?.services[0]);
    setActiveButton(id);
  };

  // handling function to check when the view port is top-0 to apply specific CSS style
  useEffect(() => {
    if (servicesData?.services) {
      const defaultService = servicesData.services.find(
        (service) => service.slug === "mep-mechanical-electrical-and-plumbing"
      );
      setSelctedData(defaultService);
      // Call getData with the default slug
      getData(`services/${defaultService.slug}`)
        .then((data) => {
          setSelctedData(data?.services[0]);
          setActiveButton(data?.services[0].title);
        })
        .catch((error) => {
          // Handle error if needed
          console.error("Error fetching default service:", error);
        });
    }
  }, [servicesData]);

  const data = servicesData?.services || [];

  return (
    <HelmetProvider>
      <Helmet>
        <title>{selectedData?.meta_title}</title>
        <meta name="description" content={selectedData?.meta_description} />
        <meta name="keywords" content={selectedData?.meta_keywords} />
      </Helmet>
      <main className=" flex flex-col">
        <BigTitle title={"our services"} />
        <FetchHandling isLoading={isLoading} isError={error} />
        {!isLoading && !error && (
          <LandingTabs
            isAtTop={isAtTop}
            activeButton={activeButton}
            onHandleClick={handleClick}
            data={data}
          />
        )}
      </main>
    </HelmetProvider>
  );
};

export default Services;
