const headerLinks = [
  {
    link: "Home",
    to: "/",
  },
  {
    link: "about us",
    to: "/about-us",
  },
  {
    link: "services",
    to: "/services",
  },
  {
    link: "projects ",
    to: "/project",
  },
  {
    link: "blog",
    to: "/blog",
  },
  {
    link: "contact us",
    to: "/contact-us",
  },
];

export default headerLinks;
