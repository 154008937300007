import React from "react";
import {
  FacebookLogo,
  InstagramLogo,
  LinkedinLogo,
} from "@phosphor-icons/react";
const FooterContact = () => {
  return (
    <div>
      <h6 className="font-medium text-2xl mb-2">Contact Us</h6>
      <span className="flex flex-col gap-y-1">
        <a
          className="font-medium transition ease-in duration-300 hover:underline"
          href="mailto:info@gsinnovators.com"
        >
          info@gsinnovators.com
        </a>
        <a
          className="font-medium transition ease-in duration-300 hover:underline"
          href="tel:+971506890569"
        >
          +971 50 689 0569
        </a>
      </span>

      <div className="flex items-center gap-x-3 mt-4">
        <span className="hover:scale-[1.1] block transition ease-in duration-300">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/gsinnovatorsuae/"
          >
            <FacebookLogo size={26} />
          </a>
        </span>
        <span className="hover:scale-[1.1] block transition ease-in duration-300">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/gsinnovators/"
          >
            <InstagramLogo size={26} />
          </a>
        </span>
        <span className="hover:scale-[1.1] block transition ease-in duration-300">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/company/gs-innovators/?viewAsMember=true"
          >
            <LinkedinLogo size={26} />
          </a>
        </span>
      </div>
    </div>
  );
};

export default FooterContact;
