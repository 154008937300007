import React, { useState, useEffect, useRef, useContext } from "react";
import LandingTab from "./LandingTab";
import ImageServices from "./ImageServices";
import ServicesHeader from "./ServicesHeader";
import MobileTab from "./MobileTab";
// context
import { ServicesContext } from "context/Services/ServicesContext";
// UI
import IsError from "components/UI/IsError";
import IsLoading from "components/UI/IsLoading";

const Services = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [fadeIn, setFadeIn] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const targetRef = useRef(null);

  // fetching data
  const { fetchData, servicesData, isLoading, error } =
    useContext(ServicesContext);

  useEffect(() => {
    if (isVisible) {
      fetchData();
    }
  }, [isVisible, fetchData]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setFadeIn(true);
    }, 50);

    return () => clearTimeout(timeout);
  }, [activeTab]);

  const handleTabClick = (index) => {
    setFadeIn(false);
    setActiveTab(index);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
        if (entry.isIntersecting) {
          // Stop tracking when the element is in view
          observer.unobserve(targetRef.current);
        }
      },
      {
        threshold: 0,
      }
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [targetRef, setIsVisible]);

  return (
    <section
      ref={targetRef}
      className={`lg:-mt-[150vh] h-[80vh] lg:h-[100vh] flex items-end relative z-[100] relative  overflow-hidden bg-white`}
    >
      <div
        className={`absolute left-0 top-0 w-full h-full bg-primary transition ease-in duration-700 ${
          isVisible ? "translate-y-[0]" : "-translate-y-[100%]"
        }`}
      ></div>
      <div className="flex flex-col gap-y-3 lg:flex-row lg:items-center  h-[75vh]  w-full lg:gap-x-24 ">
        <ServicesHeader />

        {isLoading && (
          <div className="h-[100vh]  flex text-white  items-center flex-col gap-y-2 justify-center w-full z-[100] relative">
            <IsLoading iswhite={true} />
          </div>
        )}

        {error && (
          <div className="h-[100vh]  w-full  text-white flex items-center justify-center text-white relative z-[100]">
            <IsError iswhite={true} />
          </div>
        )}

        {!isLoading && !error && (
          <>
            <LandingTab
              servicesData={servicesData}
              activeTab={activeTab}
              onMouseHandler={handleTabClick}
            />

            <MobileTab
              servicesData={servicesData}
              activeTab={activeTab}
              onMouseHandler={handleTabClick}
            />
            <ImageServices activeTab={activeTab} servicesData={servicesData} />
          </>
        )}
      </div>
    </section>
  );
};

export default Services;
