import React from "react";

const WhatWeDo = ({ content }) => {
  return (
    <section>
      <div className="flex flex-col gap-6 lg:flex-row lg:gap-26 xll:gap-32">
        <div className="flex-1">
          <h1 className="text-3xl font-philo mb-3">{content.title}</h1>
          <span className="flex flex-col gap-y-6">
            <p
              className="font-cairo"
              dangerouslySetInnerHTML={{ __html: content.text }}
            />
          </span>
        </div>
        <div className="flex-1 flex flex-col justify-center bg-gray-200 lg:rounded-tl-[42px] p-4 md:p-6 lg:p-10 h-max">
          <h3 className="text-3xl font-philo font-semibold mb-3">
            {content.list.title}
          </h3>
          <div
            className="list-style text-greyFont font-cairo"
            dangerouslySetInnerHTML={{
              __html: content.list.list,
            }}
          ></div>
        </div>
      </div>
    </section>
  );
};

export default WhatWeDo;
