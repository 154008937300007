import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./Style.css";
import Container from "components/Container/Container";
import logo from "assests/image/watermak-logo.png";

const DetailedProjectSlider = ({ data }) => {
  const images = data
    ? data.map((url) => ({
        original: url,
        thumbnail: url,
      }))
    : [];

  return (
    <div className="  bg-black">
      <div className="bg-black py-24 mt-10 relative">
        <div className="absolute top-1/2 left-1/2 z-[100000000] -translate-x-1/2 -translate-y-1/2 w-24 blur-[0.5px] opacity-50 ">
          <img src={logo} alt="" className="z-[100000] relative" />
        </div>
        <Container>
          <ImageGallery items={images} />
        </Container>
      </div>
    </div>
  );
};

export default DetailedProjectSlider;
