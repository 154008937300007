import { useEffect, useState } from "react";
import Lenis from "@studio-freight/lenis";
import { WhatsappLogo } from "@phosphor-icons/react";

import { Routes, Route } from "react-router-dom";
// layout
import Header from "layout/Header/Header";
import Footer from "layout/Footer/Footer";

// pages
import Home from "pages/Home/Home";
import About from "pages/About/About";
import Project from "pages/Projects/Project";
import Services from "pages/Services/Services";
import ProjectDetailed from "pages/Projects/ProjectDetailed";
import Blog from "pages/Blog/Blog";
import BlogDetailed from "pages/Blog/Components/BlogDetailed";
import Contact from "pages/Contact/Contact";
import NotFound from "pages/NotFound";
import ServicesDisplay from "pages/Services/ServicesDisplay";
// context
import ContextProvider from "context/ContextProvider";

// hooks
import ScrollToTop from "hooks/ScrollToTop";

const App = () => {
  useEffect(() => {
    const lenis = new Lenis({
      smooth: true,
      duration: 1.2,
    });

    let isAnimationPaused = false;

    function raf(time) {
      if (!document.body.classList.contains("overflow-hidden")) {
        if (!isAnimationPaused) {
          lenis.raf(time);
        }
      } else {
        // Optional: You can add code here to handle the case when overflow class is present.
        // For example, you might want to pause the animation.
      }

      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);

    // Optional: Add an event listener to handle changes in the body's class list
    const handleBodyClassChange = () => {
      isAnimationPaused = document.body.classList.contains("overflow-hidden");
    };

    document.body.addEventListener("transitionend", handleBodyClassChange);

    // Clean up the event listener when the component is unmounted
    return () => {
      document.body.removeEventListener("transitionend", handleBodyClassChange);
    };
  }, []);

  return (
    <div className="App">
      <ContextProvider>
        <div className="fixed right-4 bottom-4 lg:right-6 lg:bottom-6 bg-[#25D366] rounded-full p-1.5 flex items-center justify-center z-[10000]">
          <a
            href="https://wa.me/971504049109"
            target="_blank"
            rel="noopener noreferrer"
          >
            <WhatsappLogo
              size={30}
              weight="thin"
              color="white"
              className="rounded-full"
            />
          </a>
        </div>
        <ScrollToTop />
        <Header />

        <Routes>
          <Route index element={<Home />} />
          <Route path="about-us" element={<About />} />
          <Route path="project" element={<Project />} />
          <Route path="project/:slug" element={<ProjectDetailed />} />
          <Route path="services" element={<Services />} />
          <Route path="services/:slug" element={<ServicesDisplay />} />
          <Route path="blog" element={<Blog />} />
          <Route path="blogDetailed" element={<BlogDetailed />} />
          <Route path="contact-us" element={<Contact />} />
          <Route path="*" element={<NotFound />} />
        </Routes>

        <Footer />
      </ContextProvider>
    </div>
  );
};

export default App;
