import Container from "components/Container/Container";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

const LandingTabs = ({ onHandleClick, data }) => {
  return (
    <div className={`mt-14 md:mt-secondary   `}>
      <Container>
        <h1 className="text-4xl lg:text-6xl font-philo captialize mb-4">
          What we Do
        </h1>
        <div
          className={
            "flex flex-col  gap-x-8 gap-y-6 mt-3  font-philo text-xl pb-2"
          }
        >
          {data?.map((service, index) => (
            <Link
              to={service.slug}
              className={`capitalize border-b-2 border-b-gray-100 hover:border-b-primary font-light transition ease-in duration-100 }`}
              onClick={() => onHandleClick(service.slug, service.title)}
              key={index}
            >
              {service.title}
            </Link>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default LandingTabs;
