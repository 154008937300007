import Container from "components/Container/Container";
import { Link } from "react-router-dom";
const NotFound = () => {
  return (
    <main className="h-[50vh]">
      <Container>
        <div className="flex flex-col iems-center mt-20">
          <div className="flex flex-col text-xl gap-3 ">
            <h1>404 - Not Found</h1>
            <p>
              Oops! The page you are looking for might be in another castle.
            </p>
            <p>
              Let's get you back on track:{" "}
              <Link
                className="text-xl font-bold underline text-primary"
                to={"/"}
              >
                Home
              </Link>
            </p>
          </div>
        </div>
      </Container>
    </main>
  );
};

export default NotFound;
