import { Link } from "react-router-dom";
import logo from "assests/image/footer-logo.jpg";
import scrollToTop from "hooks/ScrollToTop";
const FooterLogo = () => {
  return (
    <Link
      onClick={scrollToTop}
      to={"/"}
      className=" block col-span-2 ss:col-span-3 lg:col-span-1"
    >
      <div className="w-32">
        <img src={logo} className="w-full h-full" alt="" />
      </div>
    </Link>
  );
};

export default FooterLogo;
