import BigTitle from "components/UI/BigTitle";
import { Helmet, HelmetProvider } from "react-helmet-async";

import ContactBox from "./ContactInfo/Components/ContactBox";
import ContactForm from "./ContactForm/ContactForm";
const Contact = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Contact GS Innovators for Premium Fit-Out Solutions</title>
        <meta
          name="description"
          content="Get in touch with GS Innovators for all your interior and exterior fit-out needs in UAE. Reach out for consultations, inquiries, or project collaborations. "
        />
      </Helmet>
      <main>
        <BigTitle title={"contact us"} />
        <ContactBox />
        <ContactForm />
      </main>
    </HelmetProvider>
  );
};

export default Contact;
