import { Link } from "react-router-dom";
import { useState } from "react";
import ImageSkelton from "components/UI/ImageSkelton";
// import scrollToTop from "hooks/ScrollToTop";

const ProjectBox = ({ image, projectDetais, projectName, slug, index }) => {
  const specialIndices = [
    0, 3, 4, 7, 8, 11, 12, 15, 16, 19, 20, 23, 24, 27, 28, 31, 32, 35, 36, 39,
  ];
  const [imageLoading, setImageLoading] = useState(true);
  // handle image loading to render a skelton while the image is loading
  const handleImageLoading = () => {
    setImageLoading(false);
  };

  return (
    <div
      className={` ${
        specialIndices?.includes(index)
          ? "lg:col-span-3 lg:min-h-[400px] lg:max-h-[400px] lg:h-[400px] "
          : "lg:col-span-2 lg:min-h-[650px] lg:max-h-[650px] lg:h-[650px]"
      } ${index % 4 === 0 ? "" : "mt-auto "}`}
    >
      <Link
        to={slug}
        className={`block h-full ${specialIndices?.includes(index) ? "" : ""}`}
      >
        <div className="h-full">
          <div
            className={` flex lg:h-[100%] w-full${
              specialIndices?.includes(index) ? " " : ""
            }`}
          >
            {imageLoading && <ImageSkelton />}

            <img
              onLoad={handleImageLoading}
              src={image}
              alt=""
              // loading="lazy"
              className={` w-full h-full object-cover w-full  ${
                imageLoading ? "hidden" : ""
              }  ${specialIndices?.includes(index) ? "" : ""}`}
            />
          </div>
          <div className="lg:h-[8%] flex ">
            <p className="text-lg font-cairo mt-4">
              {projectName}
              {""} / {""} {projectDetais}
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ProjectBox;
