import logo from "assests/image/footer-logo.jpg";
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <Link to={"/"}>
      <div className={`w-20 md:w-24 `}>
        <img src={logo} alt="" className="w-full h-full object-cover" />
      </div>
    </Link>
  );
};

export default Logo;
