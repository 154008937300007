import Landing from "./landing/Landing";
import MobileLanding from "./landing/MobileLanding";
import Services from "./Services/Services";
import Projects from "./Project/Projects";
import Instagram from "./Instagram/Instagram";
import { Helmet, HelmetProvider } from "react-helmet-async";
// context

const Home = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Interior and Exterior Fit Out Contractor in Abu Dhabi</title>
        <meta
          name="description"
          content="Discover GS Innovators, your best choice for interior and exterior fit-outs in Abu Dhabi. We ensure stunning designs & excellent quality from MEP to finishing."
        />
      </Helmet>
      <main>
        <Landing />
        <MobileLanding />
        <Services />
        <Projects />
        <Instagram />
      </main>
    </HelmetProvider>
  );
};

export default Home;
