import React, { useEffect, useContext, useState } from "react";
import Container from "components/Container/Container";
import FetchHandling from "components/UI/FetchHandling";
import { ProjectContext } from "context/Project/ProjectContext";
import ProjectBox from "./ProjectBox";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Project = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [visiblePages, setVisiblePages] = useState([1, 2, 3]);

  const { getProjectData, projectData, isLoading, error } =
    useContext(ProjectContext);

  useEffect(() => {
    if (!projectData) {
      getProjectData();
    }
  }, [projectData]);

  const data = projectData?.projects || [];
  const itemsPerPage = 8;

  // Calculate total pages based on filtered projects
  const filteredProjects =
    selectedCategory === null
      ? data
      : data?.filter((project) => project.category === selectedCategory);

  const totalPages = Math.ceil(filteredProjects.length / itemsPerPage);

  const getProjectsForPage = (page) => {
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredProjects.slice(startIndex, endIndex);
  };

  const handleTabClick = (category) => {
    setSelectedCategory(category === selectedCategory ? null : category);
    setCurrentPage(1); // Reset to the first page when category changes
  };

  const categories = Array.from(
    new Set(data.map((project) => project.category))
  );

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);

      // Update visible pages
      if (page > visiblePages[2] || page < visiblePages[0]) {
        setVisiblePages([page, page + 1]);
      }

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const renderPaginationButtons = () => {
    const maxVisiblePages = 3;

    const startPage = Math.max(
      1,
      currentPage - Math.floor(maxVisiblePages / 2)
    );
    const endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, index) => startPage + index
    );
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>Commercial & Residential Fit Out Contracting Projects</title>
        <meta
          name="description"
          content="Our fit out works in refers to interior finishing, exterior landscaping, restaurant fit out services in Abu Dhabi, ceiling installation, HVAC System, and best partition works. "
        />
      </Helmet>
      <main className={"py-24"}>
        <Container>
          <div>
            <h1 className="text-4xl lg:text-5xl font-philo capitalize mb-2">
              Our Projects
            </h1>

            <FetchHandling isError={error} isLoading={isLoading} />

            {isLoading || error ? null : (
              <div className="flex items-center flex-wrap gap-x-8 gap-y-2 mt-3 font-philo text-xl">
                <button
                  className={`capitalize border-b-2 font-light transition ease-in duration-100  ${
                    selectedCategory === null
                      ? " border-primary"
                      : "border-transparent"
                  }`}
                  onClick={() => handleTabClick(null)}
                >
                  All
                </button>
                {categories?.map((project, index) => (
                  <button
                    key={index}
                    className={`capitalize border-b-2 transition ease-in duration-100 ${
                      selectedCategory === project
                        ? "border-primary"
                        : "border-transparent"
                    }`}
                    onClick={() => handleTabClick(project)}
                  >
                    {project}
                  </button>
                ))}
              </div>
            )}

            {isLoading || error ? null : (
              <div className="mt-10">
                <div className="grid grid-cols-1 lg:grid-cols-5 lg:grid-rows-[600px] gap-20 ">
                  {getProjectsForPage(currentPage).map((project, index) => (
                    <ProjectBox
                      key={index}
                      slug={project.slug}
                      index={index}
                      image={project.image}
                      projectDetais={project.category}
                      projectName={project.title}
                    />
                  ))}
                </div>
              </div>
            )}

            {isLoading || error ? null : (
              <div className="mt-secondary lg:mt-primary flex items-center">
                <button
                  className={`rounded-xl px-4 py-2 border ${
                    currentPage === 1 ? "bg-gray-300" : "bg-gray-600 text-white"
                  }`}
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Prev
                </button>

                <div className="flex">
                  {renderPaginationButtons().map((page, index) => (
                    <button
                      key={index}
                      className={`rounded-xl mx-2 px-4 py-2 border ${
                        currentPage === page
                          ? "bg-primary text-white"
                          : "bg-gray-300"
                      }`}
                      onClick={() => handlePageChange(page)}
                    >
                      {page}
                    </button>
                  ))}
                </div>

                <button
                  className={`rounded-xl px-4 py-2 border ${
                    currentPage === totalPages
                      ? "bg-gray-300"
                      : "bg-gray-600 text-white"
                  }`}
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </Container>
      </main>
    </HelmetProvider>
  );
};

export default Project;
