import React, { useEffect } from "react";

import Container from "components/Container/Container";
// import "./Style.css";

const Instagram = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://static.elfsight.com/platform/platform.js";
    script.defer = true;
    script.dataset.useServiceCore = true;

    const div = document.createElement("div");
    div.className = "elfsight-app-0b077f08-1afa-4279-81b0-904537fd0665";

    const container = document.getElementById("elfsight-container");
    container.appendChild(script);
    container.appendChild(div);

    return () => {
      // Clean up the script and div elements when the component unmounts
      container.removeChild(script);
      container.removeChild(div);
    };
  }, []);

  return (
    <section className="mt-secondary lg:my-primary relative z-[100000]">
      <Container>
        <div className="border-b">
          <h4 className="text-blueFont font-medium font-cairo text-2xl lg:text-4xl mb-14">
            Our latest updates on Instagram
          </h4>
          <div className="min-w-full" id="elfsight-container"></div>
        </div>
      </Container>
    </section>
  );
};

export default Instagram;
