import React, { useState } from "react";
import { Link } from "react-router-dom";
import ImageSkelton from "components/UI/ImageSkelton";

const BlogBox = ({ data }) => {
  const blogsPerPage = 3;
  const [currentPage, setCurrentPage] = useState(1);
  const [imageIsLoading, setImageIsLoading] = useState(true);

  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = data?.slice(indexOfFirstBlog, indexOfLastBlog);

  const totalPages = Math.ceil(data?.length / blogsPerPage);
  const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const visiblePageNumbers = pageNumbers.slice(
    Math.max(currentPage - 1, 0),
    Math.min(currentPage + 2, totalPages)
  );

  const limitContent = (text, limit) => {
    return text?.length > limit ? text.slice(0, limit) + "..." : text;
  };

  const handleImageLoading = () => {
    setImageIsLoading(false);
  };

  return (
    <div className="flex-2 flex flex-col gap-y-20">
      {currentBlogs?.map(({ image, date, title, content, slug }, index) => (
        <div key={index}>
          {/* blog image */}
          <div>
            {imageIsLoading && <ImageSkelton />}
            <img
              onLoad={handleImageLoading}
              src={image}
              alt=""
              className={`${imageIsLoading ? "hidden" : "block"}`}
            />
          </div>
          {/* blog description */}
          <div className="mt-2  w-[90%]">
            <span className="font-light text-greyFont font-cairo font-medium">
              {date}
            </span>
            <h4 className="text-3xl font-philo">{title}</h4>
            <p
              className="text-sm font-light text-greyFont font-cairo"
              dangerouslySetInnerHTML={{ __html: limitContent(content, 200) }}
            />

            <Link
              state={{
                data: {
                  image: image,
                  date: date,
                  title: title,
                  content: content,
                },
              }}
              to={`/blogDetailed`}
              className="text-primary block mt-3 uppercase text-sm font-medium font-cairo"
            >
              Read more
            </Link>
          </div>
        </div>
      ))}

      {/* pagination buttons */}
      <div className="mt-4 flex items-center">
        <button
          className={`rounded-xl px-4 py-2 border ${
            currentPage === 1 ? "bg-gray-300" : "bg-gray-600 text-white"
          }`}
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Prev
        </button>

        <div className="flex">
          {visiblePageNumbers.map((page, index) => (
            <button
              key={index}
              className={`rounded-xl mx-2 px-4 py-2 border ${
                currentPage === page ? "bg-primary text-white" : "bg-gray-300"
              }`}
              onClick={() => handlePageChange(page)}
            >
              {page}
            </button>
          ))}
        </div>

        <button
          className={`rounded-xl px-4 py-2 border ${
            currentPage === totalPages
              ? "bg-gray-300"
              : "bg-gray-600 text-white"
          }`}
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default BlogBox;
